.accordion-button:not(.collapsed) {
    color: #fd7b33;
    background-color: #f7f4f3;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }
  
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fd7b33'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
  }
  
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fd7b33'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
  }

.accordion-button:focus {
    z-index: 3;
    border-color: #fd7b33 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(253, 123, 51, 0.25) !important;
  }
  
  .accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .accordion-button::after {
    color: #fd7b33 !important;  
  }

  .accordion-button:not(.collapsed) {
    color: #fd7b33 !important;
    background-color: #fff4e7 !important;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}