.form-container{
    height: 80vh;
}

.form-signin{
    width: 330px;
    padding: 15px;
    margin: auto;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
.form-signin input[type="password"] {
margin-bottom: 10px;
border-top-left-radius: 0;
border-top-right-radius: 0;
}

.login-links a{
    text-decoration: none;
    font-size: 10pt;
}

.auth-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  
  .auth-inner { 
    width: 380px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 10px 10px 10px 10px;
    border-radius: 15px;
    transition: all .3s;
  }
  
  .auth-wrapper .form-signin:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  
  .auth-wrapper h1 {
    color: #fd7b33;
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }

  .fw-normal {
    color: #fd7b33 !important;  
  }
  
  .form-floating input:focus { 
    outline: none !important;
    border-color: #fd7b33 !important;
    box-shadow: 0 0 10px #fd7b33 !important;
  }

  .btn-primary {
    color: #fff;  
    background-color: #fd7b33 !important; 
    border-color: #fd8e33; 
  }

  .btn-primary:hover {
    color: #fff;
    background-color: #f09936 !important;
    border-color: #fd7b33 !important;
  }
  
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #e94e40 !important;
    border-color: #f09936 !important;
    box-shadow: 0 0 0 0.25rem rgba(253, 123, 51, 0.8) !important;
  }